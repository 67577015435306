import React from "react";
import {
  FaAt,
  FaCalendar,
  FaCheck,
  FaClock,
  FaDollarSign,
  FaFont,
  FaGlobe,
  FaHashtag,
  FaMoneyBill,
  FaPercent,
  FaUser,
  FaWifi,
} from "react-icons/fa";

import { IRenderType } from "~src/shared/lists/types/types";

const renderIcons: {
  [T in IRenderType]: React.ReactElement;
} = {
  [IRenderType.boolean]: <FaCheck />,
  [IRenderType.cents]: <FaMoneyBill />,
  [IRenderType.currency]: <FaDollarSign />,
  [IRenderType.date]: <FaCalendar />,
  [IRenderType.email]: <FaAt />,
  [IRenderType.geo]: <FaGlobe />,
  [IRenderType.human]: <FaUser />,
  [IRenderType.monetary]: <FaMoneyBill />,
  [IRenderType.numeric]: <FaHashtag />,
  [IRenderType.percent]: <FaPercent />,
  [IRenderType.text]: <FaFont />,
  [IRenderType.timestamp]: <FaClock />,
  [IRenderType.url]: <FaWifi />,
};

interface IProps {
  renderType?: IRenderType;
}

export const IconColumnType: React.FC<IProps> = (props: IProps) => {
  const { renderType } = props;
  return <>{renderType === undefined ? null : renderIcons[renderType]}</>;
};
